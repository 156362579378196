import { v4 as uuidv4 } from "uuid";
import ForecastApp from "../Assets/ProjectImages/ForecastApp.png";
import TodoApp from "../Assets/ProjectImages/TodoApp.png";
import Pokemon from "../Assets/ProjectImages/Pokemon.png";
import Canavarlar from "../Assets/ProjectImages/canavarlar-img.jpg";

const myProject = [
  {
    name: "Monsters",
    id: uuidv4(),
    text: "Web application exercise that filters out the relevant monster as you type letters in the search box",
    github_url: "https://github.com/diyarcolakk/filteringMonsters",
    live_url: "https://filteringmonsters.netlify.app/",
    image: Canavarlar,
    techStack: ["Html", "Css", "Javascript", "React"],
  },

  {
    name: "Forecast App",
    id: uuidv4(),
    text: "Weather web application that allows you to see the 5-day forecast of any city in Turkey",
    github_url: "https://github.com/diyarcolakk/forecastApp",
    live_url: "https://forecastbydiyarcolak.netlify.app/",
    image: ForecastApp,
    techStack: ["Html", "Css", "React", "Weather API"],
  },
  {
    name: "Todo App",
    id: uuidv4(),
    text: "Todo App is a web application where you write down what you do daily. A project I created to improve myself with React",
    github_url: "https://github.com/diyarcolakk/todoApp",
    live_url: "https://todoappdiyarcolak.netlify.app",
    image: TodoApp,
    techStack: ["Html", "Css", "Javascript", "React"],
  },
  {
    name: "Pokemon",
    id: uuidv4(),
    text: "Pokemon App is my first web API project with Vanilla Javascript. It's only takes Type's of pokemon and show their pokemon types",
    github_url: "https://github.com/diyarcolakk/pokemonAPI",
    live_url: "https://diyarcolakk.github.io/pokemonAPI/",
    image: Pokemon,
    techStack: ["Html", "Css", "Vanilla Javascript"],
  },
];

export default myProject;

import { useState, useEffect } from "react";
import "./App.css";

import Header from "./Header/Header";
import Main from "./Main/Main";

function App() {
  //Animasyonun bitmesi için zaman koydum

  const [isAnimationDone, setIsAnimationDone] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsAnimationDone(true);
    }, 1500);
  }, []);

  useEffect(() => {
    if (isAnimationDone) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isAnimationDone]);

  return (
    <>
      <Header />
      <Main />
    </>
  );
}

export default App;

import "./Skill.css";
import SkillsAbout from "./Skills-About/Skills-About";
import codingDesk from "../../Assets/coding-desk.jpg";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Skills = () => {
  useEffect(() => {
    AOS.init({ duration: 3000 });
  });

  return (
    <div id="skills" className="skills">
      <div className="skilss_header">
        <h1 id="skills-container-header">Skills</h1>
        <span className="skills-bottomline"></span>
      </div>
      <main className="skills-main-container">
        <SkillsAbout />
        <div className="skills-right-container">
          <div className="skills-image-container">
            <img
              src={codingDesk}
              alt="coding desk"
              className="coding-desk"
              data-aos="fade-right"
            />
          </div>
        </div>
      </main>
    </div>
  );
};
export default Skills;

import "../Skill.css";
import { BsCodeSlash } from "react-icons/bs";
import { TbBrandJavascript } from "react-icons/tb";
import { MdDesignServices } from "react-icons/md";
import { SiPlaywright } from "react-icons/si";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const SkillsAbout = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  });

  return (
    <div className="skills-left-container">
      <h2 id="skills-header">My Skills</h2>
      <p className="skill-text">
        Below you can review which languages i work with and which frameworks do
        i use
      </p>
      <div className="per-skills" data-aos="fade-right">
        <div className="skill-container">
          <div>
            <BsCodeSlash size={30} className="icons" />
          </div>
          <div>
            <h4>Programming Language</h4>
            <p>C++ , Html , Css , Javascript , Typescript</p>
          </div>
        </div>
        <div className="skill-container">
          <div>
            <TbBrandJavascript size={30} className="icons" />
          </div>
          <div>
            <h4>Framework</h4>
            <p>React, React-Native </p>
          </div>
        </div>
        <div className="skill-container">
          <div>
            <MdDesignServices size={30} className="icons" />
          </div>
          <div>
            <h4>Design Tools</h4>
            <p> Material UI , Ant, Tailwind , Bootstrap</p>
          </div>
        </div>
        <div className="skill-container">
          <div>
            <SiPlaywright size={30} className="icons" />
          </div>
          <div>
            <h4>Test-Automation Tools</h4>
            <p>Selenium , Appium </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SkillsAbout;

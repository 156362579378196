import "./Footer.css";
import Logo from "../../Assets/newlogo.jpg";
import SocialMedia from "../Home/Social Media/SocialMedia";
const Footer = () => {
  return (
    <div id="footer" className="footer-container">
      <h1>Diyar Çolak</h1>
      <div className="footer-img-container">
        <img src={Logo} alt="Diyar Çolak Logo" />
      </div>
      <div className="footer-icon-container">
        <SocialMedia />
      </div>
      <p>© 2022 copyright all right reserved, edited by Diyar Çolak.</p>
    </div>
  );
};
export default Footer;

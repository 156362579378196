import "../Home.css";
import { FaGithub, FaCodepen } from "react-icons/fa";
import { BsLinkedin, BsMedium, BsInstagram } from "react-icons/bs";

const SocialMedia = () => {
  return (
    <div className="social-media">
      <ul className="social-media-list">
        <li>
          <a href="https://github.com/diyarcolakk">
            <FaGithub size={30} className="icons" />
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/diyarcolak/">
            <BsLinkedin size={30} className="icons" />
          </a>
        </li>
        <li>
          <a href="https://medium.com/@diyar.colaak">
            <BsMedium size={30} className="icons" />
          </a>
        </li>
        <li>
          <a href="https://codepen.io/diyarcolak">
            <FaCodepen size={30} className="icons" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/diyarclkk/">
            <BsInstagram size={30} className="icons" />
          </a>
        </li>
      </ul>
    </div>
  );
};
export default SocialMedia;

import "../Home.css";

const ResumeBtn = () => {
  return (
    <a
      href="https://drive.google.com/file/d/1Um9wL9crCwRvddAktHgpxMKBwLJ8njmF/view?usp=sharing"
      target="_blank"
      rel="noreferrer"
    >
      <button className="Resume-btn">Show Pdf</button>
    </a>
  );
};
export default ResumeBtn;

import "./Header.css";
import { useState } from "react";
import Navbar from "./Navbar/Navbar";
import { MdMenu, MdOutlineClose } from "react-icons/md";
import newLogo from "../Assets/newlogo.jpg";

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <header className="header">
        <div className="img-container">
          <a href="#">
            <img src={newLogo} alt="DiyarColak" className="logo_diyar" />
          </a>
        </div>
        <Navbar openMenu={openMenu} />
        <div className="mobil-menu">
          {!openMenu ? (
            <MdMenu size={30} onClick={() => setOpenMenu((prev) => !prev)} />
          ) : (
            <MdOutlineClose
              size={30}
              onClick={() => setOpenMenu((prev) => !prev)}
            />
          )}
        </div>
      </header>
    </>
  );
};
export default Header;

import {
  MdHome,
  MdPerson4,
  MdScreenSearchDesktop,
} from "react-icons/md";

import { GiSkills } from "react-icons/gi";

const Navbar = ({ openMenu }) => {
  const classNames = openMenu ? "navbar navbar-active" : "navbar";

  return (
    <>
      <ul className={classNames}>
        <li>
          <a href="#home">
          <MdHome size={30} />
          </a>
        </li>
        <li>
          <a href="#about-me">
          <MdPerson4 size={30} />
          </a>
        </li>
        <li>
          <a href="#skills">
          <GiSkills size={30} />
          </a>
        </li>
        <li>
          <a href="#project">
          <MdScreenSearchDesktop size={30} />
          </a>
        </li>
      </ul>
    </>
  );
};
export default Navbar;

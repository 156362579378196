import "./About.css";
import MiddleInAbout from "./MiddleInAbout/MiddleInAbout";
const AboutMe = () => {
  return (
    <div id="about-me" className="about">
      <div  className="about-h1">
        <h1 id="about-about">About Me</h1>
        <span className="bottom-line"></span>
      </div>
      <MiddleInAbout />
    </div>
  );
};
export default AboutMe;

import "./Home.css";
import SocialMedia from "./Social Media/SocialMedia";
import ResumeBtn from "./ResumeBtn/ResumeBtn";
import Image from "./ImageContainer/Image";
import { Typewriter } from "react-simple-typewriter";

const Home = () => {
  const typicalArr = [
    "Software Developer",
    "Web Developer",
    "Front-end Developer",
  ];

  return (
    <div id="home" className="main-container">
      <div className="main-left-container">
        <h1>
          Hi,
          <br /> I'm <span id="home-message"> Diyar</span>
        </h1>
        <p className="typical-container">
          <Typewriter
            words={typicalArr}
            loop={5}
            cursor
            cursorStyle="|"
            typeSpeed={100}
            deleteSpeed={100}
            delaySpeed={1000}
          />
        </p>
        <ResumeBtn />
        <SocialMedia />
      </div>
      <Image />
    </div>
  );
};
export default Home;

import "../Home.css";
import MainImage from "../../../Assets/img.jpg";
const Image = () => {
  return (
    <div className="main-right-container">
      <div className="image-container">
        <img src={MainImage} alt="MainFoto" className="main_image" />
      </div>
    </div>
  );
};
export default Image;

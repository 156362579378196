import AboutMe from "./About/AboutMe";
import Home from "./Home/Home";
import Skills from "./Skills/Skills";
import "./Main.css";
import Project from "./Project/Project";
import Footer from "./Footer/Footer";
const Main = () => {
  return (
    <>
      <div className="whole-container ">
        <div className="main">
          <Home />
        </div>
        <div className="about-container">
          <AboutMe />
        </div>
        <div className="skills-container">
          <Skills />
        </div>
        <div className="project-blog">
          <Project />
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </>
  );
};
export default Main;

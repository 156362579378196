import "../Project.css";
import { GoLinkExternal } from "react-icons/go";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const ProjectCard = ({
  name,
  github_url,
  live_url,
  image,
  techStack,
  text,
}) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  });
  return (
    <>
      <div className="card" data-aos="fade-right">
        <div className="image-container-project">
          <img src={image} alt={name} className="img-section-container" />
        </div>
        <div className="text-content">
          <div className="links_container">
            <div className="links_router">
              <a href={live_url}>Live</a>
              <span>
                <GoLinkExternal size={20} />
              </span>
            </div>
            <div className="links_router">
              <a href={github_url}>Github</a>
              <span>
                <GoLinkExternal size={20} />
              </span>
            </div>
          </div>
          <div className="content-project-container">
            <div className="app_nameContent">
              <h2>{name}</h2>
              <div>{text}</div>
            </div>
            <div>
              <div className="tech-stack">
                {techStack.map((element, index) => (
                  <span key={index}>{element}</span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProjectCard;

import "../About.css";
import AOS from "aos";
import "aos/dist/aos.css";

import ReactDev from "../../../Assets/react-dev.png";
import { useEffect } from "react";

const MiddleInAbout = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  });

  return (
    <div className="about-content-container">
      <div className="left-about" data-aos="fade-right">
        <div className="about-left-img-container">
          <img src={ReactDev} alt="" className="about-img" />
        </div>
      </div>
      <div className="right-about" data-aos="fade-down">
        <div className="right-about-text-container">
          <h2>I'm Diyar</h2>
          <ul className="about-list">
            <li>
              <span className="about-preferences">Birthday</span> 4 October 1998
            </li>
            <li>
              <span className="about-preferences">City</span> : Maltepe,Istanbul
            </li>
            <li>
              <span className="about-preferences">University</span> : Zonguldak
              Karaelmas University
            </li>
            <li>
              <span className="about-preferences">Department</span>: Computer
              Engineering
            </li>
            <li>
              <span className="about-preferences">Degree</span> : Bachelor
            </li>
          </ul>
          <p className="about-text">
            Hi! I am Diyar from Turkey. I am Front-end Developer The most
            precious thing in our life is time. That's why I love to do
            something for myself and to improve myself. Remember, you can review
            some of my accounts. (such as GitHub, LinkedIn, Medium)
          </p>
        </div>
      </div>
    </div>
  );
};
export default MiddleInAbout;

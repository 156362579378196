import "./Project.css";
import ProjectCard from "./ProjectCard/ProjectCard";
import myProject from "../../ProjectAPI/myProjects";
import { useState } from "react";

const Project = () => {
  const [loadmore, setLoadMore] = useState(4);

  return (
    <>
      <div id="project" className="project">
        <div className="project-h1-container">
          <h1>Projects</h1>
          <span className="project-bottomline"></span>
        </div>
        <div className="project-card-container">
          {myProject.slice(0, loadmore).map((perProject) => (
            <ProjectCard key={perProject.id} {...perProject} />
          ))}
        </div>
        <div className="btn-container">
          <button
            className="loadmore-btn"
            onClick={() => setLoadMore((pre) => pre + 4)}
          >
            Load More
          </button>
        </div>
      </div>
    </>
  );
};
export default Project;
